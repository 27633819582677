.image-bg {
  background-image: url('../../assets/images/KarenCanche.jpg');
  background-size: cover;
  width: 100%;
}


.image-bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }